import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import debounce from "lodash.debounce";
import {
  Button,
  FormLabel,
  Grid,
  Paper,
  Typography,
  MenuItem,
  Select
} from "@material-ui/core";

import DirectionSnackbar from "components/Snackbar/Snackbar";
import IconTooltip from "components/IconTooltip/IconTooltip";
import NumberInput from "components/NumberInput/NumberInput";
import Spinner from "components/Spinner/Spinner";
import UnselfishDialog from "components/UnselfishDialog/UnselfishDialog";

import SimulationApi from "MetaCell/api/Simulation";

import HelperUtils from "MetaCell/helper/HelperUtils";

import DirectoryExplorerSelector from "MetaCell/selectors/DirectoryExplorer";
import SimulationSettingsSelector from "MetaCell/selectors/SimulationSettings";

import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";

const MetacellExport = ({ classes, open, onClose }) => {
  const sweptVariables =
    useSelector(SimulationSettingsSelector.getSweptVariables) || [];
  const simulationId = useSelector(
    DirectoryExplorerSelector.getSimulationOpenId
  );
  const simulations = useSelector(DirectoryExplorerSelector.getSimulations);
  const store = useSelector(state => state);
  let simulationVariables = sweptVariables.filter(
    variable => variable.simulation === simulationId
  );
  let unusedSweepVariables = HelperUtils.getUnusedSweepVariableNames(
    store,
    simulationVariables
  );
  // filter out unused variables, as they are not necessary for the mask generation
  let simulationVariablesForm = unusedSweepVariables.length
    ? simulationVariables.filter(
        variable =>
          !unusedSweepVariables.includes(variable.variableName) &&
          !variable.is_dependent
      )
    : simulationVariables.filter(variable => !variable.is_dependent);

  const [message, setMessage] = useState("");
  const [disabledActions, setDisabledActions] = useState("");
  const [loadingActions, setLoadingActions] = useState([]);
  const [nrPoints, setNrPoints] = useState(100);
  const [exportFormat, setExportFormat] = useState("GDS");
  // a dynamic form based on the used variables in the structure
  // initialize each variable with an empty string
  // users need to fill in the data in order to generate the mask
  const [formData, setFormData] = useState(
    simulationVariablesForm.reduce((acc, variable) => {
      acc[variable.variableName] = "";
      return acc;
    }, {})
  );
  const [formErrors, setFormErrors] = useState({});

  // cancel getMaskStatusWithDelay on unmount
  useEffect(() => {
    return () => {
      getMaskStatusWithDelay.cancel();
    };
  }, []);

  useEffect(() => {
    setFormData(prevFormData => {
      let newFormData = {};
      unusedSweepVariables = HelperUtils.getUnusedSweepVariableNames(
        store,
        simulationVariables
      );
      simulationVariablesForm = unusedSweepVariables.length
        ? simulationVariables.filter(
            variable =>
              !unusedSweepVariables.includes(variable.variableName) &&
              !variable.is_dependent
          )
        : simulationVariables.filter(variable => !variable.is_dependent);
      for (const variable of simulationVariablesForm) {
        newFormData[variable.variableName] =
          prevFormData[variable.variableName] || ""; // Preserve previous values
      }

      // Avoid unnecessary state updates
      return JSON.stringify(newFormData) === JSON.stringify(prevFormData)
        ? prevFormData
        : newFormData;
    });
  }, [simulationVariables]);

  const getMask = async () => {
    SimulationApi.getSimulationMask(simulationId)
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${simulations.byId[simulationId].name}_metacell_mask.export.zip`
        );
        link.click();
        window.URL.revokeObjectURL(url);
        setDisabledActions("");
        setLoadingActions([]);
        setFormErrors({});
        getMaskStatusWithDelay.cancel();
      })
      .catch(e => {
        const error_message =
          e.response?.data || e.message || "Failed to generate mask";
        setMessage(error_message);
        setDisabledActions("");
        setLoadingActions([]);
        setFormErrors({});
      });
  };

  const getMaskStatus = () => {
    return SimulationApi.getSimulationMaskStatus(simulationId)
      .then(resp => resp.data)
      .then(data => {
        const status = data.status;
        if (status === "QUEUED" || status === "RUNNING" || status === "IDLE") {
          return getMaskStatusWithDelay();
        } else if (status === "DONE") {
          return getMask();
        } else if (status === "ERROR") {
          const error_message = data.errors || "Failed to generate mask";
          setMessage(error_message);
          setDisabledActions("");
          setLoadingActions([]);
          setFormErrors({});
          return Promise.reject(new Error(error_message)); // Propagate the error
        }
      })
      .catch(e => {
        const error_message =
          e.response?.data || e.message || "Failed to generate mask";
        setMessage(error_message);
        setDisabledActions("");
        setLoadingActions([]);
        setFormErrors({});
      });
  };

  const validateAndCalculateVariables = () => {
    const errors = {};
    // sometimes if a user creates variables on the fly and opens the export
    // dialog inbetween, the formData still contains unused variables (old ones)
    // so we filter them here
    if (Object.keys(formData).length === 0) {
      return [{}, {}];
    }

    Object.entries(formData).forEach(([key, value]) => {
      value = value ? value.replace(/\s/g, "") : "";
      if (key && !value) {
        errors[key] = "This field is required.";
      }
      if (isNaN(value)) {
        errors[key] = "This field must be a number.";
      }
    });

    if (Object.keys(errors).length !== 0) {
      return [{}, errors];
    }

    let calculatedVariables = { ...formData };
    const dependentVariables = unusedSweepVariables.length
      ? simulationVariables.filter(
          variable =>
            !unusedSweepVariables.includes(variable.variableName) &&
            variable.is_dependent
        )
      : simulationVariables.filter(variable => variable.is_dependent);
    for (const dependentVariable of dependentVariables) {
      const dependentVariableName = dependentVariable.variableName;
      const dependentVariableFormula = dependentVariable.formula;
      const dependentVariableValue = HelperUtils.resolveMathExpression(
        dependentVariableFormula,
        formData
      );
      calculatedVariables[dependentVariableName] = dependentVariableValue;
    }
    return [calculatedVariables, {}];
  };

  const getMaskStatusWithDelay = debounce(() => {
    getMaskStatus();
  }, 20000);

  const startMaskGeneration = () => {
    const [calculatedVariables, errors] = validateAndCalculateVariables();
    setFormErrors(errors);
    if (Object.keys(errors).length !== 0) return;

    setDisabledActions("mask");
    setLoadingActions(["mask"]);
    return SimulationApi.startSimulationMask(
      simulationId,
      nrPoints,
      exportFormat.toLowerCase(),
      calculatedVariables
    )
      .then(() => getMaskStatusWithDelay())
      .catch(e => {
        const error_message =
          e.response?.data || e.message || "Failed to generate mask";
        setMessage(error_message);
        setDisabledActions("");
        setLoadingActions([]);
        setFormErrors({});
      });
  };

  const handleInputChange = e => {
    const { name, value } = e.target;

    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));

    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: ""
      }));
    }
  };

  const getExportFormatOptions = () => {
    return ["GDS", "OAS", "STL", "DXF"];
  };

  return (
    <>
      <UnselfishDialog
        name="ExportDialog"
        open={open}
        onClose={onClose}
        maxWidth="lg"
      >
        <div style={{ minWidth: 500 }}>
          <Grid item xs={12}>
            <Paper className={classes.paper} style={{ padding: "30px" }}>
              <Typography variant="h6">{"MetaCell Export"}</Typography>
              <Grid
                container
                spacing={4}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Grid item xs={6}>
                    <FormLabel
                      style={{ fontSize: 12, marginRight: "30px" }}
                      htmlFor="nrPoints"
                    >
                      Max Number of GDS Points
                      <IconTooltip
                        text={
                          "Set the desired maximal number of points for each structure cross-section to define non-straight lines within the Mask export in GDS format."
                        }
                      />
                    </FormLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <NumberInput
                      name="nrPointsInput"
                      inputProps={{
                        style: {
                          textAlign: "left",
                          minWidth: 100,
                          marginLeft: "10px"
                        }
                      }}
                      onChange={event => {
                        setNrPoints(event.target.value);
                      }}
                      value={nrPoints}
                      autoFocus
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Grid item xs={6}>
                    <FormLabel style={{ fontSize: 12 }} htmlFor="Format">
                      Export format
                    </FormLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      value={exportFormat || "GDS"}
                      inputProps={{
                        name: "FormatOptions"
                      }}
                      onChange={ev => setExportFormat(ev.target.value)}
                      style={{ width: 200, paddingLeft: "10px" }}
                    >
                      {getExportFormatOptions().map((format, index) => (
                        <MenuItem
                          key={index}
                          value={format}
                          name="FormatSelectOptions"
                        >
                          {format}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
                {simulationVariablesForm.length > 0 && (
                  <Grid item xs={12}>
                    <Typography variant="h6" style={{ marginBottom: "20px" }}>
                      {"Used variables"}
                    </Typography>
                    {simulationVariablesForm.map(variable => (
                      <Grid
                        key={variable.id}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-end"
                        }}
                      >
                        <Grid item xs={2}>
                          <FormLabel
                            style={{ fontSize: 12, marginRight: "30px" }}
                            htmlFor={variable.variableName}
                          >
                            {variable.variableName}
                          </FormLabel>
                        </Grid>
                        <Grid item xs={10}>
                          <NumberInput
                            required
                            test-data="UserVariablesInput"
                            inputProps={{
                              style: {
                                textAlign: "left",
                                minWidth: 100,
                                marginLeft: "10px"
                              }
                            }}
                            name={variable.variableName}
                            onChange={handleInputChange}
                            value={formData[variable.variableName] || ""}
                            error={!!formErrors[variable.variableName]}
                            helperText={formErrors[variable.variableName]}
                            allowNegative={true}
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    gap: "16px",
                    justifyContent: "center"
                  }}
                >
                  <Button
                    test-data="maskButton"
                    name="Mask"
                    className={classes.buttonMargin}
                    variant="contained"
                    onClick={startMaskGeneration}
                    disabled={disabledActions.indexOf("mask") !== -1}
                  >
                    {loadingActions.includes("mask") && (
                      <Spinner
                        className={classes.circularProgress}
                        size={20}
                        style={{ marginRight: "10px" }}
                      />
                    )}
                    Export
                  </Button>
                  <Button
                    test-data="closeButton"
                    name="Close"
                    className={classes.buttonMargin}
                    variant="contained"
                    onClick={onClose}
                    disabled={disabledActions.indexOf("mask") !== -1}
                  >
                    Close
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </div>
      </UnselfishDialog>

      {message && (
        <DirectionSnackbar
          message={message}
          onCloseCallback={() => setMessage("")}
        />
      )}
    </>
  );
};

export default withErrorBoundary(MetacellExport);
