import Axios from "axios";
import Action from "MetaCell/actions/Simulation";
import GenericApi from "Api";

/**
 * A class created to prepare data and perform http operations
 * related to a simulation.
 * @author Akira Kotsugai
 */
export default class SimulationApi {
  /**
   * it generates an url to result plots
   * @param {Number} id - the plot id
   * @returns {String} the url
   */
  static getResultPlotsUrl = (id = "") =>
    `${GenericApi.getBaseUrl()}/resultplots/${id}`;

  /**
   * @returns {String} an url to get lightweight running result plots
   */
  static getLightWeightRunningPlotsUrl = () =>
    `${GenericApi.getBaseUrl()}/resultplots/lite`;

  /**
   * it generates an url to plot progress
   * @param {Number} id - the plot id
   * @returns {String} the url
   */
  static getResultPlotProgressUrl = id =>
    `${this.getResultPlotsUrl(id)}/status`;

  /**
   * it generates an url for cross section of the plot
   * @param {Number} id - the plot id,
   * @param {String} orientation - orientation of cross section
   * @param {Number} index - row/col index for the cross section
   * @returns {String} the url
   */
  static getResultPlotCrossSection = (id, orientation, index) =>
    `${this.getResultPlotsUrl(
      id
    )}/cross_section?orientation=${orientation}&index=${index}`;

  /**
   * it generates an url for scatter point retrieval of a scatter plot
   * @param {Number} id - the scatter plot id,
   * @param {Number} pointIndex - pointIndex of scatter point
   * @returns {String} the url
   */
  static getResultPlotScatterPoint = (id, pointIndex) =>
    `${this.getResultPlotsUrl(id)}/scatter_points?pointIndex=${pointIndex}`;

  /**
   * it generates an url to export a simulation
   * @param {Number} simulationId - the simulation id
   */
  static getExportUrl = simulationId =>
    `${GenericApi.getBaseUrl()}/simulations/${simulationId}/export`;

  /**
   * it generates an url to export a simulation in a gds/oas/dxf/stl format
   * @param {Number} simulationId - the simulation id
   */
  static getStartSimulationMaskUrl = simulationId =>
    `${GenericApi.getBaseUrl()}/simulation/${simulationId}/start_mask`;

  /**
   * @returns {String} url to get status of a mask generation
   */
  static getSimulationMaskStatusUrl = simulationId =>
    `${GenericApi.getBaseUrl()}/simulation/${simulationId}/mask_progress`;

  static stopSimulationMaskUrl = simulationId =>
    `${GenericApi.getBaseUrl()}/simulation/${simulationId}/stop_mask`;

  /**
   * it generates an url to get the mask of a simulation
   */
  static getSimulationMaskUrl = simulationId =>
    `${GenericApi.getBaseUrl()}/simulation/${simulationId}/get_mask`;

  /**
   * it generates an url for simulation task backend endpoint
   * @param {Number} simulationJobId - the simulation task id
   * @returns {String} the url
   */
  static getJobDetailsUrl = simulationJobId =>
    `${GenericApi.getBaseUrl()}/simulationjobs/${simulationJobId}`;

  /**
   * it generates the url to get simulation tasks by simulation id
   * @param {Number} simulationId - the simulation id
   * @returns {String} the url
   */
  static getSimulationJobsUrl = simulationId =>
    `${GenericApi.getBaseUrl()}/simulationjobs/?simulation=${simulationId}&job_type=MCS`;

  /**
   * @returns {String} a url to the all jobs endpoint
   */
  static getAllSimulationJobsUrl = () =>
    `${GenericApi.getBaseUrl()}/simulationjobs/?job_type=MCS`;

  /**
   * @returns
   */
  static getAllModeAnalysisJobsUrl = () =>
    `${GenericApi.getBaseUrl()}/simulationjobs/?job_type=MA`;

  /**
   * it generates the url to get mode analysis by simulation id
   * @param {Number} simulationId - the simulation id
   * @returns {String} the url
   */
  static getModeAnalysisJobsUrl = simulationId =>
    `${GenericApi.getBaseUrl()}/simulationjobs/?simulation=${simulationId}&job_type=MA`;

  /**
   * @param {Number} id - the job id
   * it generates the url to get the result status of a simulation task
   */
  static getTaskResultStatusUrl = id =>
    `${GenericApi.getBaseUrl()}/simulationjobs/${id}/status`;

  /**
   * @param {Number} jobId - the job id
   * it generates the url to get the configuration of a simulation job
   */
  static getJobConfigurationUrl = jobId =>
    `${GenericApi.getBaseUrl()}/simulationjobs/${jobId}/configuration`;

  /**
   * @param {Number} jobId - the job id
   * it generates the url to get the plots of a simulation job
   */
  static getJobPlotsUrl = jobId =>
    `${GenericApi.getBaseUrl()}/resultplots/?simulationJob=${jobId}`;

  /**
   * @param {Number} jobId - the job id
   * it generates the url to get the plot params of a simulation job
   */
  static getJobPlotParamsUrl = jobId =>
    `${GenericApi.getBaseUrl()}/simulationjobs/${jobId}/params`;

  /**
   * @param {Number} jobId - the job id
   * it generates the url to export the configuration of a simulation job
   */
  static getExportJobConfigUrl = jobId =>
    `${GenericApi.getBaseUrl()}/simulationjobs/${jobId}/export_config`;

  /**
   * it generates the url to run a simulation
   */
  static getSimulateUrl = id =>
    `${GenericApi.getBaseUrl()}/simulations/${id}/simulate`;

  /**
   * it generates the url to run a simulation with the same plots
   */
  static getSimulateWithSamePlotsUrl = id =>
    `${this.getSimulateUrl(id)}?keep_plots`;

  /**
   * it makes a request to run a simulation
   * @param {Number} simulationId - the simulation id
   * @param {Function} errorHandler
   * @param {Boolean} keepPlots
   * @returns {Function} a function that receives and uses the redux dispatcher
   */
  static run = (id, errorHandler, keepPlots) => {
    return dispatch => {
      const url = keepPlots
        ? this.getSimulateWithSamePlotsUrl(id)
        : this.getSimulateUrl(id);
      return GenericApi.runApiCall(
        Axios.post(url),
        "Failed to start simulation"
      )
        .then(response => response.data)
        .then(simulationJob => dispatch(Action.upsertJobs([simulationJob])))
        .catch(error => {
          if (typeof errorHandler === "function") {
            errorHandler(error.response);
          }
          console.log("Simulation API: failed to start simulation");
        });
    };
  };

  /**
   * @param {Number} id - the simulation id
   * @param {Object} sweepPoint - the object containing an specific value for each variable
   * @param {Object} incidentLight - the string with the name of the incident light
   * @returns {Function} a function that receives and uses the redux dispatcher
   */
  static runModeAnalysis = (id, sweepPoint, incidentLight) => {
    return dispatch => {
      const url = this.getSimulateUrl(id);
      return Axios.post(url, {
        job_type: "mode_analysis",
        analysis_sweep_point: sweepPoint,
        incident_light: incidentLight
      })
        .then(response => response.data)
        .then(simulationJob =>
          dispatch(Action.upsertModeAnalysisJobs([simulationJob]))
        );
    };
  };

  /**
   * it makes a request to the simulation jobs endpoint and retrieve the ones
   * that are linked to the simulation
   * @param {Number} simulationId - the simulation id
   * @returns {Function} a function that receives and uses the redux dispatcher
   */
  static getSimulationJobs = simulationId => {
    return dispatch => {
      const url = SimulationApi.getSimulationJobsUrl(simulationId);
      return Axios.get(url)
        .then(response => response.data)
        .then(simulationTasks => dispatch(Action.setJobs(simulationTasks)));
    };
  };

  /**
   * it makes a request to the simulation jobs endpoint and retrieve the ones
   * that are linked to the simulation
   * @param {Number} simulationId - the simulation id
   * @returns {Function} a function that receives and uses the redux dispatcher
   */
  static getModeAnalysisJobs = simulationId => {
    return dispatch => {
      const url = SimulationApi.getModeAnalysisJobsUrl(simulationId);
      return Axios.get(url)
        .then(response => response.data)
        .then(modeAnalysis =>
          dispatch(Action.setModeAnalysisJobs(modeAnalysis))
        );
    };
  };

  /**
   * it makes a request to the status backend endpoint
   * @param {Number} simulationJobId - the simulation task id
   * @param {Boolean} modeAnalysis - whether it is mode analysis or normal simulation
   * @returns {Function} a function that receives the redux dispatcher
   */
  static getTaskResultStatus = (simulationJobId, modeAnalysis) => {
    return dispatch => {
      const url = SimulationApi.getTaskResultStatusUrl(simulationJobId);
      return GenericApi.runApiCall(
        Axios.get(url),
        "Simulation does not progress."
      )
        .then(resp => resp.data)
        .then(data => {
          const upsertData = [{ id: simulationJobId, ...data }];
          dispatch(
            modeAnalysis
              ? Action.upsertModeAnalysisJobs(upsertData)
              : Action.upsertJobs(upsertData)
          );
          return data;
        });
    };
  };

  static getSimpleTaskResultStatus = simulationJobId => {
    const url = this.getTaskResultStatusUrl(simulationJobId);
    return GenericApi.runApiCall(
      Axios.get(url),
      "Simulation does not progress."
    );
  };

  /**
   * it makes a request to the export simulation endpoint
   * @param {Number} simulationId - the simulation id
   * @returns {Promise} the response
   */
  static export = simulationId =>
    GenericApi.runApiCall(
      Axios({
        url: SimulationApi.getExportUrl(simulationId),
        method: "GET",
        responseType: "blob",
        headers: {
          "Content-Type": "application/x-zip-compressed"
        }
      }).then(resp => resp.data),
      "Failed to export simulation file"
    );

  /**
   * it makes a request to the job configuration endpoint
   * @param {Number} jobId - the simulation job id
   * @returns {Promise} the response
   */
  static getJobConfiguration = jobId => {
    const url = this.getJobConfigurationUrl(jobId);
    return Axios.get(url);
  };

  /**
   * it makes a request to the job plot endpoint
   * @param {Number} jobId - the simulation job id
   * @returns {Promise} the response
   */
  static getJobPlots = jobId => {
    const url = this.getJobPlotsUrl(jobId),
      config = {
        responseType: "blob"
      };
    return Axios.get(url, config);
  };

  /**
   * it makes a request to the job plot params endpoint
   * @param {Number} jobId - the simulation job id
   * @returns {Promise} the response
   */
  static getJobPlotParams = jobId => {
    const url = this.getJobPlotParamsUrl(jobId);
    return Axios.get(url);
  };

  /**
   * it makes a request to the job details endpoint
   * @param {Number} jobId - the simulation job id
   * @returns {Promise} the response
   */
  static getJobDetails = jobId => {
    const url = this.getJobDetailsUrl(jobId);
    return Axios.get(url);
  };

  /**
   * it makes a request to add a plot belonging to the given job
   * @param {Number} jobId - the simulation job id
   * @returns {Promise} the response
   */
  static addJobPlot = (jobId, plotParams) => {
    const url = this.getResultPlotsUrl();
    return Axios.post(url, { simulationJob: jobId, ...plotParams });
  };

  /**
   * it makes a request to add a probe plot belonging to the given job
   * @param {Number} jobId - the simulation job id
   * @returns {Promise} the response
   */
  static addProbeJobPlot = jobId => {
    const url = this.getResultPlotsUrl();
    return Axios.post(url, { simulationJob: jobId, plotType: "PROBE" });
  };

  /**
   * it makes a request to delete a plot
   * @param {Number} plotId - the plot id
   * @returns {Promise} the response
   */
  static deleteJobPlot = plotId => {
    const url = this.getResultPlotsUrl(plotId);
    return Axios.delete(url);
  };

  /**
   * it makes a request to delete a plot
   * @param {Number[]} plotIds - the plot ids
   * @returns {Promise} the response
   */
  static deleteJobPlots = plotIds => {
    const url = this.getResultPlotsUrl("");
    return Axios.delete(url, { data: plotIds });
  };

  /**
   * it makes a request to get a plot
   * @param {Number} plotId - the plot id
   * @returns {Promise} the response
   */
  static getJobPlot = (plotId, unwrap = false) => {
    var url = this.getResultPlotsUrl(plotId),
      config = {
        responseType: "blob"
      };
    if (unwrap) url += "?unwrap";
    return Axios.get(url, config);
  };

  /**
   * it makes a request to get the cross section of a plot
   * @param {Number} plotId - the plot id
   * @param {String} orientation - the cross section orientation
   * @param {Number} index - the row/col index for cross section
   * @returns {Promise} the response
   */
  static getJobPlotCrossSection = (plotId, orientation, index) => {
    if (plotId && orientation && index) {
      const url = this.getResultPlotCrossSection(plotId, orientation, index);
      return GenericApi.runApiCall(
        Axios.get(url),
        "Cannot fetch cross section for this plot."
      );
    }
  };

  /**
   * it makes a request to get the scatter point of a scatter plot
   * @param {Number} plotId - the plot id
   * @param {Number} pointIndex - the index for scatter point retrieval
   * @returns {Promise} the response
   */
  static getJobPlotScatterPoint = (plotId, pointIndex) => {
    if (plotId && pointIndex !== null && pointIndex !== undefined) {
      const url = this.getResultPlotScatterPoint(plotId, pointIndex);
      return GenericApi.runApiCall(
        Axios.get(url),
        "Cannot fetch the scatter point information"
      );
    }
  };

  /**
   * it makes a request to get the progress of a plot
   * @param {Number} plotId - the plot id
   * @returns {Promise} the response
   */
  static getJobPlotProgress = plotId => {
    const url = this.getResultPlotProgressUrl(plotId);
    return GenericApi.runApiCall(Axios.get(url), "Plot does not progress.");
  };

  /**
   * it makes a request to export a job configuration
   * @param {Number} jobId - the job id
   * @returns {Promise} the response
   */
  static exportJobConfig = jobId => {
    const url = this.getExportJobConfigUrl(jobId);
    return GenericApi.runApiCall(
      Axios({
        url,
        method: "GET",
        responseType: "blob"
      }),
      "Failed to export configuration file."
    );
  };

  /**
   * it makes a request to update a plot
   * @param {Number} plotId - the job id
   * @param {Object} data - the new data
   * @returns {Promise} the response
   */
  static updateJobPlotbox = (plotId, data) => {
    const url = this.getResultPlotsUrl(plotId);
    return GenericApi.runApiCall(
      Axios.patch(url, data).then(res => res.data),
      "Failed to update plot"
    );
  };

  /**
   * it stops a running simulation job
   * @param {Number} jobId - the job id
   */
  static stopSimulationJob = jobId => {
    const url = `${this.getJobDetailsUrl(jobId)}/stop`;
    return Axios.post(url);
  };

  /**
   * it deletes a simulation job
   * @param {Number} jobId - the id of the job
   * @returns {Function} a function that receives the redux dispatcher
   */
  static deleteSimulationJob = jobId => {
    return dispatch => {
      const url = this.getJobDetailsUrl(jobId);
      return Axios.delete(url)
        .then(res => res.data)
        .then(() => dispatch(Action.delete([jobId])));
    };
  };

  /**
   * it deletes multiple simulation jobs
   * @param {Number[]} jobIds - the id of the jobs
   * @returns {Function} a function that receives the redux dispatcher
   */
  static deleteSimulationJobs = jobIds => {
    return dispatch => {
      const url = this.getJobDetailsUrl("");
      return Axios.delete(url, { data: jobIds })
        .then(res => res.data)
        .then(() => dispatch(Action.delete(jobIds)));
    };
  };

  /**
   * @returns {Promise} a request to the lightweight running result plots
   */
  static getLightWeightRunningPlots = () => {
    return Axios.get(this.getLightWeightRunningPlotsUrl()).then(
      res => res.data
    );
  };

  /**
   * @returns {Promise} a promise that makes a request to get all simulation jobs
   */
  static getAllSimulationJobs = () => {
    return Axios.get(this.getAllSimulationJobsUrl()).then(res => res.data);
  };

  /**
   * @returns {Promise} a promise that makes a request to get all mode analysis jobs
   */
  static getAllModeAnalysisJobs = () => {
    return Axios.get(this.getAllModeAnalysisJobsUrl()).then(res => res.data);
  };

  /**
   * @param {Number} plotId
   * @returns {Promise} a promise that makes a request to stop a plot
   */
  static stopPlot = plotId => {
    return Axios.post(`${this.getResultPlotsUrl(plotId)}/stop`);
  };

  /**
   * it makes a request to start a simulation mask generation
   * @param {Number} jobId - the job id
   * @returns {Promise} the response
   */
  static startSimulationMask = (jobId, nrPoints, exportFormat, formData) => {
    const url = this.getStartSimulationMaskUrl(jobId);
    return Axios.post(url, {
      nr_points: nrPoints,
      export_format: exportFormat,
      form_data: formData
    })
      .then(res => res)
      .catch(error => {
        console.log(
          "Error fetching mask status:",
          error.response?.data || error.message
        );
        return Promise.reject(error);
      });
  };

  static getSimulationMaskProgress = jobId => {
    const url = this.getSimulationMaskStatusUrl(jobId);
    return GenericApi.runApiCall(Axios.get(url));
  };

  /**
   * it stops a running mask job
   * @param {Number} jobId - the simulation id
   */
  static stopMaskJob = jobId => {
    const url = this.stopSimulationMaskUrl(jobId);
    return Axios.post(url);
  };

  /**
   * it makes a request to get a simulation mask
   * @param {Number} jobId - the job id
   * @returns {Promise} the response
   */
  static getSimulationMaskStatus = jobId => {
    const url = this.getSimulationMaskStatusUrl(jobId);
    return Axios.get(url);
  };

  /**
   * it makes a request to get a simulation mask
   * @param {Number} jobId - the job id
   * @returns {Promise} the response
   */
  static getSimulationMask = jobId => {
    const url = this.getSimulationMaskUrl(jobId);
    return GenericApi.runApiCall(
      Axios({
        url,
        method: "GET",
        responseType: "blob"
      }),
      "Failed to export mask file."
    );
  };
}
