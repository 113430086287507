import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import ReactLinkify from "react-linkify";

export default function DirectionSnackbar(props) {
  const [open, setOpen] = React.useState(true);
  const [transition, setTransition] = React.useState(undefined);

  function TransitionUp(props) {
    setOpen(true);
    return <Slide {...props} direction="up" />;
  }

  const handleClick = Transition => () => {
    setTransition(() => Transition);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason == "clickaway" && props.persistent) {
      setTimeout(() => {
        setOpen(false);
      }, 5000);
    } else {
      setOpen(false);
    }
    if (props.onCloseCallback) {
      props.onCloseCallback();
    }
  };

  return (
    <div>
      <Snackbar
        open={open}
        onClose={handleClose}
        TransitionComponent={transition}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        message={
          RegExp(
            "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?"
          ).test(props.message) ? (
            <span id="message-id" style={{ whiteSpace: "pre-wrap" }}>
              <ReactLinkify
                properties={{ target: "_blank", style: { color: "white" } }}
              >
                {props.message}
              </ReactLinkify>
            </span>
          ) : (
            <span id="message-id" style={{ whiteSpace: "pre-wrap" }}>
              {props.message}
            </span>
          )
        }
        anchorOrigin={
          props.anchor
            ? props.anchor
            : { vertical: "bottom", horizontal: "right" }
        }
      />
    </div>
  );
}
