import React, { PureComponent } from "react";
import UserZone from "./UserZone/UserZone";
import MainMenu from "./MainMenu/MainMenu";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import "./BaseApp.css";
import ConfirmDialog from "BaseApp/ConfirmDialog/ConfirmDialog";
import AccountDialog from "BaseApp/AccountDialog/AccountDialog";
import DocumentDialog from "BaseApp/DocumentDialog/DocumentDialog";
import { connect } from "react-redux";
import UserApi from "BaseApp/api/User";
import BannerSnackbar from "BaseApp/Banner/Banner";

const theme = createMuiTheme({ typography: { useNextVariants: true } });

/**
 * A component to hold the components that will always be shown when the user is logged in,
 * It is the header of the page containing the main navigation menu, the user zone and global components.
 * The content of the page can be defined by wrapping another component with this component.
 * @author Akira Kotsugai
 */
export class BaseApp extends PureComponent {
  /**
   * it fetches the logged in user as soon as it mounts
   */
  componentDidMount() {
    this.props.fetchUserDataAction();
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <BannerSnackbar />
        <div className="top-bar">
          <UserZone />
          <MainMenu />
        </div>
        {this.props.children}
        <AccountDialog />
        <ConfirmDialog />
        <DocumentDialog />
      </ThemeProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchUserDataAction: () => dispatch(UserApi.getLoggedInUser())
  };
};

export default connect(null, mapDispatchToProps)(BaseApp);
