import React, { PureComponent } from "react";
import { connect } from "react-redux";
import ExplorerPanel from "./components/ExplorerPanel/ExplorerPanel";
import ExplorerTree from "./components/ExplorerTree/ExplorerTree";
import ExplorerForm from "./components/ExplorerForm/ExplorerForm";
import { itemType } from "./components/ExplorerTree/ExplorerTree";
import { withStyles } from "@material-ui/core/styles";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import Import from "components/Import";
import Axios from "axios";
import UserSelector from "BaseApp/selectors/User";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import Helper from "MetaCell/helper/DirectoryExplorer";
import DirectionSnackbar from "components/Snackbar/Snackbar";

const styles = theme => ({
  root: {
    height: theme.spacing(35),
    flexGrow: 1,
    width: "100%"
  }
});

/**
 * A component to allow the user to organize simulations with a tree explorer,
 * a form and a control panel
 * @author Akira Kotsugai
 */
export class DirectoryExplorer extends PureComponent {
  /**
   * we create a reference to be passed all the way down the open simulation item
   */
  constructor() {
    super();
    this.openSimulationRef = React.createRef();
    this.state = {
      projectsRefs: {},
      importDialogOpen: false,
      importDialogLoading: false,
      importDialogError: "",
      isLoading: false,
      snackbar: {
        visible: false,
        message: ""
      }
    };
  }

  /**
   * it calls the data fetcher for the directory explorer and for the current page
   * as soon as the component mounts.
   */
  componentDidMount() {
    this.props.fetchData();
  }

  /**
   * everytime we notice that a new simulation has been opened, we focus on the open simulation,
   * however, we have to wait a few miliseconds because sometimes the parent item is collapsed
   * and it takes times for all simulations to be shown when the parent is expanded.
   * @param {Object} prevProps - the previous props
   */
  componentDidUpdate(prevProps, prevState) {
    const { openSimulationId } = this.props,
      projectsIds = this.props.projects.allIds,
      prevProjectsIds = prevProps.projects.allIds;

    if (projectsIds !== prevProjectsIds) {
      this.updateRowsRefs(projectsIds);
    }

    if (
      openSimulationId !== -1 &&
      openSimulationId !== prevProps.openSimulationId
    ) {
      setTimeout(
        () => this.openSimulationRef.current.firstElementChild.click(),
        400
      );
    }
  }

  /**
   * it sets the projects refs
   * @param {Object[]} projectsIds - the projects ids
   */
  updateRowsRefs(projectsIds) {
    let projectsRefs = {};
    projectsIds.forEach(id => {
      projectsRefs[id] = React.createRef();
    });
    this.setState({ projectsRefs });
  }

  /**
   * it is supposed to be passed down to the form.
   * it calls the correct action to add a tree item based on the selected item's type.
   * it also increments the item name if it already exists.
   * everytime we add a project, we focus on it, but we have to wait a bit
   * to make sure that the new item is already rendered
   * @callback
   */
  addItem = () => {
    const {
      addProjectItem,
      addSimulationItem,
      form,
      selectedItem,
      projects,
      simulations,
      grouperType
    } = this.props;
    if (selectedItem.type === itemType.USER) {
      const formattedProjectName = Helper.handleRepeatedItemName(
        form,
        Object.values(projects.byId)
      );
      addProjectItem(formattedProjectName).then(project => {
        setTimeout(() => {
          this.state.projectsRefs[project.id].current.firstElementChild.click();
        }, 400);
      });
    } else if (selectedItem.type === itemType.PROJECT) {
      const formattedSimulationName = Helper.handleRepeatedItemName(
        form,
        Object.values(simulations.byId).filter(
          simulation => simulation[grouperType] === selectedItem.id
        )
      );
      addSimulationItem(formattedSimulationName, selectedItem.id);
    }
  };

  /**
   * it is supposed to be passed down to the tree component.
   * it opens the confirmation dialog to delete the selected item from the tree if its not the user item
   * @callback
   */
  removeItem = () => {
    const {
      showConfirmDialog,
      selectedItem,
      deleteProjectsAndSimulations
    } = this.props;

    if (selectedItem !== null && selectedItem.type !== itemType.USER) {
      const title = "Delete";
      const multipleItemsSelected = Array.isArray(selectedItem);
      const itemNames = multipleItemsSelected
        ? selectedItem
            .filter(({ type }) => type !== itemType.USER)
            .map(({ name }) => "\n\t-" + name)
        : ["\n\t-" + selectedItem.name];
      const message = `Are you sure you want to delete the following items?${itemNames.join(
        ""
      )}`;
      const confirmAction = () => {
        // Set isLoading state to true when user confirms the action
        this.setState({ isLoading: true });

        return deleteProjectsAndSimulations(
          multipleItemsSelected ? selectedItem : [selectedItem]
        ).then(() => {
          // Set isLoading state to false after the remove process is complete
          this.setState({ isLoading: false });
        });
      };
      showConfirmDialog(title, message, confirmAction, undefined, true);
    }
  };

  /**
   * it is supposed to be passed down to the tree component.
   * it calls an action to edit a tree item if its not the user item
   * @callback
   */
  editItem = () => {
    const { editItem, selectedItem } = this.props;
    if (selectedItem !== null && selectedItem.type !== itemType.USER) {
      var selectedItemFromParent = {};
      switch (selectedItem.type) {
        case "simulation": {
          selectedItemFromParent = {
            ...selectedItem,
            name: this.props.simulations.byId[selectedItem.id].name
          };
          break;
        }
        case "project": {
          selectedItemFromParent = {
            ...selectedItem,
            name: this.props.projects.byId[selectedItem.id].name
          };
          break;
        }
      }
      editItem(selectedItemFromParent);
    }
  };

  /**
   * it tells the state that the import dialog is open and removes the errors
   */
  openImportDialog = () => {
    this.setState({ importDialogOpen: true, importDialogError: "" });
  };

  /**
   * it saves the editings from the currently opened page and exports the selected
   * simulation
   */
  export = async () => {
    const {
      simulations,
      selectedItem,
      saveEditings,
      exportSimulation
    } = this.props;

    // Set isLoading state to true before starting the export process
    this.setState({ isLoading: true });

    const simulation = Object.values(simulations.byId).find(
      ({ id }) => id === selectedItem.id
    );
    await saveEditings();
    exportSimulation(selectedItem.id)
      .then(data => {
        const url = window.URL.createObjectURL(
          new Blob([data], { type: "application/x-zip-compressed" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${simulation.name}.data.zip`);
        link.click();
        window.URL.revokeObjectURL(url);

        // Set isLoading state to false after the export process is complete
        this.setState({ isLoading: false });
      })
      .catch(error => {
        console.log(error);

        // Set isLoading state to false if there is an error during the export process
        this.setState({ isLoading: false });
      });
  };

  /**
   * it tells the state that the import dialog is loading and submits the accepted file
   * @param {File[]} acceptedFiles - an array containing the dropped file
   */
  handleDrop = acceptedFiles => {
    if (acceptedFiles.length > 0) {
      const formData = new FormData();
      formData.append("file", acceptedFiles[0]);
      this.setState({ importDialogLoading: true });
      return this.submit(formData);
    }
  };

  /**
   * it tells the state that dialog is not loading and it is closed.
   */
  handleClose = () => {
    this.setState({ importDialogOpen: false, importDialogLoading: false });
  };

  /**
   * it submits data to the import simulation url
   * @param {FormData} formData - the data to be imported
   */
  submit = formData => {
    this.setState({
      snackbar: {
        visible: false,
        message: ""
      }
    });
    return Axios.post(this.props.getImportUrl(), formData)
      .then(({ data }) => {
        if (data.messages && data.messages.length)
          this.setState({
            snackbar: {
              visible: true,
              message: data.messages[0]
            }
          });
        this.handleClose();
        this.props.onImportSuccess();
      })
      .catch(e => {
        this.setState({
          importDialogLoading: false,
          importDialogError: e?.response?.data || "This is not a valid file"
        });
      });
  };

  render() {
    const {
      classes,
      user,
      projects,
      simulations,
      updateForm,
      selectedItem,
      form
    } = this.props;
    return (
      <div className={classes.root} test-data="directoryExplorer">
        <ExplorerPanel
          onExport={this.export}
          selectedItem={selectedItem}
          onDelete={this.removeItem}
          onEdit={this.editItem}
          onImport={this.openImportDialog}
          isLoading={this.state.isLoading}
        />
        {projects.loaded && simulations.loaded ? (
          <>
            <Import
              open={this.state.importDialogOpen}
              loading={this.state.importDialogLoading}
              onClose={this.handleClose}
              onDrop={this.handleDrop}
              message="Drag and drop your json or zipped file here, or click to select file"
              error={this.state.importDialogError}
              accept="application/json, .zip"
            />
            <ExplorerTree
              user={user}
              projects={projects}
              simulations={simulations}
              simulationOpener={this.props.openSimulation}
              openSimulationId={this.props.openSimulationId}
              openSimulationRef={this.openSimulationRef}
              projectsRefs={this.state.projectsRefs}
              finalItemType={this.props.finalItemType}
              grouperType={this.props.grouperType}
              updateSimulation={this.props.updateSimulation}
              updateProject={this.props.updateProject}
              updateSelectedItem={this.props.updateSelectedItem}
              updateEditingValue={this.props.updateEditingValue}
              editingItem={this.props.editingItem}
              selectedItem={this.props.selectedItem}
            />
            {selectedItem !== null &&
            !Array.isArray(selectedItem) &&
            selectedItem.id &&
            selectedItem.type !== itemType.SIMULATION ? (
              <ExplorerForm
                handleUpdate={updateForm}
                selectedItem={selectedItem}
                inputValue={form}
                onSubmit={this.addItem}
              />
            ) : null}
          </>
        ) : null}
        {this.state.snackbar.visible && (
          <DirectionSnackbar
            persistent={true}
            message={this.state.snackbar.message}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: UserSelector.getUser(state)
});

const mapDispatchToProps = dispatch => {
  return {
    showConfirmDialog: (
      title,
      message,
      confirmAction,
      cancelAction,
      isReduxAction
    ) =>
      dispatch(
        ConfirmDialogAction.show(
          title,
          message,
          confirmAction,
          cancelAction,
          isReduxAction
        )
      )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorBoundary(withStyles(styles)(DirectoryExplorer)));
