import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

const drawerWidth = 230;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },

  drawerPaper: {
    width: drawerWidth,
    marginTop: "calc(128px + var(--top-bar-offset, 0px))",
    height: "calc(100% - 128px)"
  },

  list: {
    paddingTop: 0
  },

  menuButton: {
    position: "fixed",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 1
  },

  hide: {
    display: "none"
  },

  mainContentWrapper: {
    flexGrow: 1,
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(17),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginTop: "calc(var(--top-bar-offset, 0px))"
  },

  mainContentWrapperOpen: {
    marginLeft: drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },

  drawerHeader: {
    position: "fixed",
    top: "50%",
    left: drawerWidth - 18,
    transform: "translateY(-50%)",
    zIndex: 1
  },

  boldIcon: {
    fontWeight: "bolder"
  }
}));

function ControlMenu(props) {
  const classes = useStyles();
  const { content } = props;
  const [open, setOpen] = useState(true);

  const handleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className={classes.root}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawer}
          edge="start"
          className={`${classes.menuButton} ${open && classes.hide}`}
          title="Show Menu"
        >
          <ChevronRightIcon className={classes.boldIcon} />
        </IconButton>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          classes={{
            paper: classes.drawerPaper
          }}
          open={open}
        >
          <List classes={{ padding: classes.list }}>{content}</List>
        </Drawer>
      </div>
      <div className={`${classes.drawerHeader} ${!open && classes.hide}`}>
        <IconButton
          onClick={handleDrawer}
          title="Hide Menu"
          color="inherit"
          aria-label="close drawer"
        >
          <ChevronLeftIcon className={classes.boldIcon} />
        </IconButton>
      </div>
      <div
        className={`${classes.mainContentWrapper} ${
          open ? classes.mainContentWrapperOpen : ""
        } mainContentWrapper`}
      >
        {props.children}
      </div>
    </>
  );
}

ControlMenu.propTypes = {
  children: PropTypes.node,
  content: PropTypes.node.isRequired
};

export default ControlMenu;
