import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Typography, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import UserSelector from "BaseApp/selectors/User";

const BANNER_HEIGHT = 50;

const mapStateToProps = state => ({
  version: UserSelector.getVersion(state)
});

const BannerSnackbar = ({ version }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // Check if the user has dismissed the snackbar before
    const dismissed = document.cookie.includes("bannerDismissed=true");
    if (!dismissed && version !== "DEV_BUILD" && version !== null) {
      const [day, month, year] = version.split(/[\s.]+/);
      // ignore time for simplicity. months are 0 indexed
      const buildDate = new Date(Date.UTC(year, month - 1, day));
      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

      // Check if the build date is within the past week
      if (buildDate >= oneWeekAgo) {
        setOpen(true);
        document.documentElement.style.setProperty(
          "--top-bar-offset",
          `${BANNER_HEIGHT}px`
        );
      }
    }
  }, [version]);

  const handleClose = () => {
    setOpen(false);
    document.documentElement.style.setProperty("--top-bar-offset", "0px");
    // Save dismissal in cookies to prevent showing the snackbar again expires in 10 days
    document.cookie = "bannerDismissed=true; max-age=864000";
  };

  // we temporarily ask standalone users to check their emails for release notes
  // TODO: remove this once the wiki is made available for standalone
  return (
    <>
      {/* using useStyles creates problems when code is minified and deployed */}
      {open && (
        <div
          style={{
            width: "100%",
            height: `${BANNER_HEIGHT}px`,
            backgroundColor: "#3f51b5",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1000,
            transition: "top 0.3s ease-in-out"
          }}
        >
          {process.env.REACT_APP_STANDALONE ? (
            <Typography>
              We have updated our software! Check your emails for release notes.
            </Typography>
          ) : (
            <Typography>
              We have updated our software! Check out our{" "}
              <a
                href="/wiki"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "white", textDecoration: "underline" }}
              >
                wiki
              </a>{" "}
              for release notes.
            </Typography>
          )}
          <IconButton
            onClick={handleClose}
            style={{ color: "white", marginLeft: "16px" }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </div>
      )}
    </>
  );
};

export default connect(mapStateToProps)(BannerSnackbar);
